#career-quiz-root {
  --scrollbar-width: 17px;
  --breakout-width: calc(100vw - var(--scrollbar-width, 16px));
  --scollbar-width-half: var(--scrollbar-width) / 2;

  width: 100%;
  min-height: 100vh;
  max-width: none !important;
  position: relative;
  /* left: 50%;
  right: 50%;
  width: var(--breakout-width) !important;
  margin-left: calc(-50vw + var(--scollbar-width-half)) !important;
  margin-right: calc(-50vw + var(--scollbar-width-half)) !important; */

}

.dg.ac {
  z-index: 999;
}

body {
  --parallax-offset-x: 0px;
  --parallax-offset-y: 0px;
  --parallax-rotation: 0deg;
}